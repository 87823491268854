import React, { useState, useEffect } from 'react';
import './AgriloChangePassword.css';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdEditSquare } from "react-icons/md";
import { MdLockPerson } from "react-icons/md";
import { CiCircleQuestion } from "react-icons/ci";
import axios from 'axios';

const AgriloChangePassword = ({ access, onMenuItemSelect }) => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [error, setError] = useState('');
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    }
    const handleRepeatNewPasswordChange = (event) => {
        setRepeatNewPassword(event.target.value);
    }

    const onBackClick = () => {
        onMenuItemSelect('account');
    }
    const onSaveClick = () => {
        //check if all fields are filled
        if (password === '' || newPassword === '' || repeatNewPassword === '') {
            setError('Please fill all fields');
            alert('Please fill all fields');
        }
        //check if new password and repeat new password are the same
        else if (newPassword !== repeatNewPassword) {
            setError('Passwords do not match');
            alert('Passwords do not match');
        }
        // update password
        else {
            axios.put('https://www.pimasens.com/api/updateUserPassword/', {
                password: password,
                NewPassword: newPassword
            }, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error) {
                        setError('An error occurred');
                    } else {
                        alert('Password changed successfully');
                    }
                })
                .catch(error => {
                    if (error.response.data.error === 'Incorrect password.') {
                        setError('Incorrect password');
                        alert('Incorrect password');
                    } else {
                        setError('An error occurred');
                        alert('An error occurred');
                    }
                });
        }
    }
    return (
        <div className="Agrilo-ChangePassword">
            <button className='Agrilo-ChangePassword-back-button' onClick={onBackClick}>
                <MdKeyboardArrowLeft className='Agrilo-ChangePassword-back-icon' size={35} />
            </button>
            <div className='Agrilo-ChangePassword-profile-box'>
                <h1 className='Agrilo-ChangePassword-h1'>Change your password</h1>
                <h2 className='Agrilo-ChangePassword-h2'>Current password</h2>
                <input className='Agrilo-ChangePassword-input' type='password' placeholder='Current password' value={password} onChange={handlePasswordChange} />
                <h2 className='Agrilo-ChangePassword-h2'>New password</h2>
                <input className='Agrilo-ChangePassword-input' type='password' placeholder='New password' value={newPassword} onChange={handleNewPasswordChange} />
                <h2 className='Agrilo-ChangePassword-h2'>Repeat new password</h2>
                <input className='Agrilo-ChangePassword-input' type='password' placeholder='Repeat new password' value={repeatNewPassword} onChange={handleRepeatNewPasswordChange} />
                <div className='Agrilo-ChangePassword-button-box'>
                    <button className='Agrilo-ChangePassword-cancel-button' onClick={onBackClick}>Cancel</button>
                    <button className='Agrilo-ChangePassword-save-button' onClick={onSaveClick}> Set new password</button>
                </div>
            </div>
        </div>
    );
};

export default AgriloChangePassword;