import React, { useRef, useState, useEffect } from "react";

const CustomVideoPlayer = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateProgress = () => {
      const percentage = (video.currentTime / video.duration) * 100;
      setProgress(percentage);
    };

    const handleVideoEnd = () => {
      setPlaying(false);
    };

    video.addEventListener("timeupdate", updateProgress);
    video.addEventListener("ended", handleVideoEnd);

    return () => {
      video.removeEventListener("timeupdate", updateProgress);
      video.removeEventListener("ended", handleVideoEnd);
    };
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  const handleSeek = (event) => {
    if (!videoRef.current) return;

    const progressBar = event.currentTarget;
    const clickPosition = event.nativeEvent.offsetX;
    const progressBarWidth = progressBar.clientWidth;
    const seekTime = (clickPosition / progressBarWidth) * videoRef.current.duration;

    videoRef.current.currentTime = seekTime;
  };

  return (
    <div style={styles.videoContainer}>
      <video
        ref={videoRef}
        muted
        className="custom-header-video"
        style={styles.video}
        onClick={togglePlay}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {!playing && (
        <button onClick={togglePlay} style={styles.playButton}>
          ▶
        </button>
      )}

      {/* Seek bar container, now flexible */}
      <div style={styles.progressBarWrapper}>
        <div style={styles.progressBarContainer} onClick={handleSeek}>
          <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  videoContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "600px",
    minWidth: "300px", // Ensures it doesn’t shrink too much
    alignSelf: "center",
  },
  video: {
    width: "100%",
    boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
    cursor: "pointer",
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0,0,0,0.6)",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "15%", // Scales with video size
    maxWidth: "60px",
    height: "15%",
    maxHeight: "60px",
    fontSize: "1.5rem",
    cursor: "pointer",
  },
  progressBarWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  progressBarContainer: {
    width: "100%", // Stays centered and proportional
    maxWidth: "500px",
    height: "8px",
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "4px",
    cursor: "pointer",
    margin: "-2.5%",
    zIndex: "2",
  },
  progressBar: {
    height: "100%",
    background: "#ff5733",
  },
};

export default CustomVideoPlayer;
