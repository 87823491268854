import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AgriloPinnedBox.css';
import PinnedIcon from '../assets/agriloIcons/PinnedIcon.png';
import { PiGearSixLight } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
const AgriloPinnedBox = ({ access, prefill }) => {
    const [weather, setWeather] = useState([])
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const date = new Date();
    const [loading, setLoading] = useState(true);
    const [chemicals, setChemicals] = useState([])
    const [currentChemical, setCurrentChemical] = useState([]);
    const [option, setOption] = useState(false);
    const [error, setError] = useState('');
    const [averages, setAverages] = useState([]);
    const [data, setData] = useState([]);
    const addChemical = (newChemical) => {
        setChemicals([...chemicals, newChemical]);
    };

    const removeChemical = (chemicalIndex) => {
        const updatedChemicals = [...chemicals];
        updatedChemicals.splice(chemicalIndex, 1);
        setChemicals(updatedChemicals);
    };

    const handleOptionClicked = () => {
        setOption(!option)
        setCurrentChemical(chemicals);
    };

    const handleCancelClicked = () => {
        setOption(false);
        setChemicals(currentChemical);
    };


    const handleAddClicked = () => {
        addChemical({ SpecID: 1, CropTypeID: 1, VariantID: 1 });
    };

    const updateItem = (index, key, value) => {
        const updatedChemicals = [...chemicals];
        updatedChemicals[index][key] = parseInt(value);
        setChemicals(updatedChemicals);
    };

    const handleItemChange = (event, name, index) => {
        updateItem(index, name, event.target.value);
    };

    const handleRemoveClicked = (index) => {
        removeChemical(index)
    };

    const handleSaveClicked = () => {
        setOption(false);
        if (chemicals != currentChemical) {
            let dataToSend = {
                Type: "AgriloWebPinnedBox"
                , BoxPref: chemicals
            };
            axios.put('https://pimasens.com/api/user/AgriloWebAppPref/', dataToSend, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error) {
                        setError('An error occurred');
                    } else {
                        setAverages(data.BoxPref);
                    }
                })
                .catch(error => {
                    setError('An error occurred');
                });
        }
    };

    useEffect(() => {
        axios.get('https://pimasens.com/api/user/AgriloWebAppPref/?type=AgriloWebPinnedBox', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error === "No existing pref") {
                    setError('An error occurred');
                } else {
                    setAverages(data.BoxPref);
                    setChemicals(data.BoxPref);
                    setLoading(false);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
        if (loading === true) {
            let dataToSend = { Type: "AgriloWebPinnedBox", BoxPref: [] };
            axios.post('https://pimasens.com/api/user/AgriloWebAppPref/', dataToSend, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error) {
                        setError('An error occurred');
                    } else {
                        setAverages(data.BoxPref);
                        setLoading(false);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setError('An error occurred');
                });
        }
    }, []);


    useEffect(() => {
        if (averages.length == 0) {
            setData([])
        } else {
            var ids = ""
            for (var i = 0; i < averages.length; i++) {
                ids += averages[i].CropTypeID + "," + averages[i].VariantID
                if (i != averages.length - 1) {
                    ids += ";"
                }
            }

            axios.get('https://pimasens.com/api/agriloTest/getAverage/?Month=2&Year=2025&CropChemicalPairs=' + ids, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    setData(data);
                })
                .catch(error => {
                    setError('An error occurred');
                });
        }
    }, [averages]);

    return (
        <div className="AgriloPinnedBox">
            <img src={PinnedIcon} alt="shot1" className="AgriloPinnedBox-icon" />
            <h1 className='AgriloPinnedBox-h1'>Pinned</h1>
            <PiGearSixLight className='AgriloOverviewBox-setting-icon' size={40} onClick={handleOptionClicked} />
            <div className='AgriloPinnedBox-line1' />
            {option && (
                <div className="AgriloOverviewBox-popup-menu">
                    <AiOutlineClose size={20} className='AgriloOverviewBox-close-icon' onClick={handleCancelClicked} />
                    <div className='AgriloOverviewBox-option-box'>
                        {chemicals[0] && (
                            <div className='AgriloOverviewBox-option-div'>
                                {/* <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[0].SpecID} className='AgriloTestsBox-filter-select' onChange={(e) => handleItemChange(e, "SpecID", 0)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[0].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 0)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[0].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 0)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(0)}>Remove</h1>
                            </div>
                        )}
                        {chemicals[1] && (
                            <div className='AgriloOverviewBox-option-div'>
                                {/* <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[1].SpecID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "SpecID", 1)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[1].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 1)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[1].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 1)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(1)}>Remove</h1>
                            </div>
                        )}
                        {chemicals[2] && (
                            <div className='AgriloOverviewBox-option-div'>
                                {/* <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[2].SpecID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "SpecID", 2)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[2].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 2)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[2].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 2)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(2)}>Remove</h1>
                            </div>
                        )}
                        {chemicals[3] ? (
                            <div className='AgriloOverviewBox-option-div'>
                                {/* <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[3].SpecID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "SpecID", 3)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[3].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 3)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[3].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 3)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(3)}>Remove</h1>
                            </div>
                        ) : (
                            <div className='AgriloOverviewBox-option-add' onClick={handleAddClicked}>
                                <CiCirclePlus size={60} />
                                <h1 className='AgriloOverviewBox-add-text'>Add test</h1>
                            </div>
                        )}
                        <button className='AgriloOverviewBox-save-button' onClick={handleSaveClicked}>Save</button>
                    </div>
                </div>
            )}
            {option && (
                <div className='AgriloOverviewBox-overlay'></div>
            )}
            <div className='AgriloPinnedBox-items'>
                {data[0] && (
                    <OverviewBox
                        prefill={prefill}
                        data = {data[0]}
                        type="soil"
                        value="7.0"
                        metric="PPM"
                        date={date.toLocaleString('default', { month: 'long' })}
                    />
                )}

                {data[1] && (
                    <OverviewBox
                        prefill={prefill}
                        data = {data[1]}
                        type="soil"
                        value="7.0"
                        metric="PPM"
                        date={date.toLocaleString('default', { month: 'long' })}
                    />
                )}
                {data[2] && (
                    <OverviewBox
                        prefill={prefill}
                        data = {data[2]}
                        type="soil"
                        value="data"
                        metric="PPM"
                        date={date.toLocaleString('default', { month: 'long' })}
                    />
                )}
                {data[3] && (
                    <OverviewBox
                        prefill={prefill}
                        data = {data[3]}
                        type="soil"
                        value="7.0"
                        metric="PPM"
                        date={date.toLocaleString('default', { month: 'long' })}
                    />
                )}
                {/* <ChemOverviewBox
                    name="Nitrate"
                    value1="24"
                    metric="PPM"
                    value2="18"
                    month1="JUN"
                    value3="22"
                    month2="MAY"
                    value4="20"
                    month3="APR"
                /> */}
                {/* <OverviewBox
                    type="soil"
                    value="7.0"
                    metric="PPM"
                    date="PRE-SEASON"
                /> */}
                {/* <OverviewBox
                    type="Moisture"
                    value="45"
                    metric="%"
                    date="JULY AVG"
                />
                <OverviewBox
                    type="Temperature"
                    value="30°"
                    metric="CELCIUS"
                    date="JULY AVG"
                />
                <OverviewBox
                    type="Rainfall"
                    value="0.5"
                    metric="INCHES"
                    date="24h"
                /> */}
            </div>
        </div>
    );
};

export default AgriloPinnedBox;

const OverviewBox = (prop) => {
    const { data, prefill } = prop
    var number = 0;
    var CropName = prefill.crop_types[data.CropTypeID - 1].CropName
    var VariantName = prefill.variants[data.VariantID - 1].VariantName
    if (data.error != undefined) {
        number = 0
    } else {
        number = data.average_test_result
    }
    return (
        <div className="AgriloPinnedBox-overview">
            <h1 className="AgriloPinnedBox-overview-header">Chemical Overview</h1>
            {/* <svg className='AgriloPinnedBox-overview-dots' xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                <circle cx="2" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="8" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="14" cy="2" r="2" fill="#B4B4B4" />
            </svg> */}
            <div className='AgriloPinnedBox-overview-line' />
            <div className='AgriloPinnedBox-overview-type'>{CropName}</div>
            <div className='AgriloPinnedBox-overview-type'>{VariantName}</div>
            <h1 className='AgriloPinnedBox-overview-value'>{number.toFixed(3)}</h1>
            <h1 className='AgriloPinnedBox-overview-metric'>{prop.metric}</h1>
            <h1 className='AgriloPinnedBox-overview-date'>{prop.date}</h1>
        </div>
    );
};

const ChemOverviewBox = (prop) => {
    return (
        <div className="AgriloPinnedBox-ChemOverviewBox">
            <h1 className="AgriloPinnedBox-ChemOverviewBox-header">Chemical Overview</h1>
            <div className='AgriloPinnedBox-ChemOverviewBox-line' />
            <svg className='AgriloPinnedBox-ChemOverviewBox-dots' xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                <circle cx="2" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="8" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="14" cy="2" r="2" fill="#B4B4B4" />
            </svg>
            <div></div>
            <div className="AgriloPinnedBox-ChemOverviewBox-box one">
                <div className='AgriloPinnedBox-ChemOverviewBox-type'>{prop.name}</div>
                {/* last test */}
                <h1 className='AgriloPinnedBox-ChemOverviewBox-big-value'>{prop.value1}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric1'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-label'>LAST TEST</h1>
            </div>
            {/* month1 */}
            <div className='AgriloPinnedBox-ChemOverviewBox-box two'>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-value two'>{prop.value2}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric two'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-month one'>{prop.month1}</h1>
            </div>
            {/* month2 */}
            <div className='AgriloPinnedBox-ChemOverviewBox-box three'>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-value three'>{prop.value3}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric three'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-month two'>{prop.month2}</h1>
            </div>
            {/* month3 */}
            <div className='AgriloPinnedBox-ChemOverviewBox-box four'>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-value four'>{prop.value4}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric four'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-month three'>{prop.month3}</h1>
            </div>
        </div>
    );
};