import React from 'react';
import './ZenroProductsPage.css';
import { LinearGradient } from 'react-text-gradients';

import microscope from '../assets/zenroIcons/brain.png';
import AI from '../assets/zenroIcons/feedback.png';
import leaf from '../assets/zenroIcons/accessibility.png';
import zenroProdVideo from '../assets/zenroVideos/FinalComp_1.mp4';
import agriloPhones from '../assets/pimasensScreenshots/zenroPhones.png'

import CustomVideoPlayer from '../agrilo_prof_pages/components/CustomVIdeoPlayer';
import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const ZenroProductPage = () => {
    return (
        <div className="ZenroProductPage">
            <NavBar />
            <main className='ZenroProductPage-Container'>
                <div className='ZenroPP-titleDiv'>
                    <div style={{paddingBottom:"5rem"}}>
                    <h1 className='ZenroPP-Title'>
                        <LinearGradient gradient={['to left', 'rgb(126, 166, 255), rgb(246, 127, 141)']} className="Products-header">
                            Zenro
                        </LinearGradient>
                    </h1>
                    <p className='ZenroPP-TitleText'>Measure, Analyze, Report. All in one place.</p>
                    </div>
                </div>
                <div className="ZenroPP-Main-Content" style={{marginBottom: '5rem'}}>
                    <div className="ZenroPP-InfoBox">
                        <img src={microscope} alt='Microscope image' />
                        <h3>Track Horomones & Other health metrics effortlessly</h3>
                        <p>The Zenro App monitors your health by analyzing hormonal signals from your body, and calculates a score.</p>
                        <p>This value is compared to to the user's unique baseline, allowing the app to detect subtle changes and provide insights into your overall well-being.</p>
                    </div>
                    <div className="ZenroPP-InfoBox">
                        <img src={AI} alt='robot brain image' />
                        <h3>Generate instant feedback on every test</h3>
                        <p>Zenro generates personalized recommendations using a machine learning model trained on extensive health data.</p>
                        <p>Based on your current status - whether it's high, low, severe, or normal - the app provides tailored guidance to help you maintain or improve your well-being effectively.</p>
                    </div>
                    <div className="ZenroPP-InfoBox">
                    <img src={leaf} alt='Microscope image' />
                        <h3>accessibility features to explore your health portfolio</h3>
                        <p>Your history includes a list of all tests you have performed, along with a detailed view that provides feedback and export options.</p>
                        <p>Additionally, you can track your hormone levels over time with a graph, view morning and evening averages, and analyze trends in your data.</p>
                    </div>
                </div>
                <div className='ZenroPP-Video-Content'>
                    <CustomVideoPlayer videoSrc={zenroProdVideo}/>
                    <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">Explore Zenro</h2>
                            <p className='ZenroPP-TitleText'>The Zenro app is your all-in-one solution for mental wellness, providing the tools you need to track, understand, and improve your well-being.</p>
                    </div>
                </div>
                <div className='ZenroPP-Main-Content' style={{background: 'transparent', alignItems: 'center'}}>
                    <div className="ZenroPP-TextBlock">
                        <h2 className="ZenroPP-TextBlock-Title">Download</h2>
                        <p className='ZenroPP-TitleText'>Take control of your mental wellness with Zenro. Track your progress, gain insights, and access personalized feedback—all in one powerful app. Download now and start your journey to a healthier mind.</p>
                        <div className='ZenroPP-Download-Container'>
                            <button className='ZenroPP-Download-Button-Style'>iOS</button>
                            <button className='ZenroPP-Download-Button-Style'>Android</button>
                        </div>
                    </div>
                    <img src={agriloPhones} alt='image of app on two phones' style={{width: "30vw"}}/>
                </div>
            </main>
            <BottomNav/>
        </div>
    );
};

export default ZenroProductPage;