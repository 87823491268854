import React from 'react';
import './ProductsPage.css';
import { LinearGradient } from 'react-text-gradients'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

import zenroPromoImage from '../assets/pimasensScreenshots/zenro_phone_cropped.png';
import agriloPromoImage from '../assets/pimaspecScreenshots/agrilo_phone_cropped.png';
import { FaDownload } from "react-icons/fa";

import chromeimg from '../SensorInfo/agriloSensorImages/chromium.avif'
import chromepdf from '../SensorInfo/agriloSensorPDFS/ChromiumSensor.pdf'

import fluorineimg from '../SensorInfo/agriloSensorImages/fluorine.jpg'
import fluorinepdf from '../SensorInfo/agriloSensorPDFS/FluorideSensor.pdf'

import phosphateimg from '../SensorInfo/agriloSensorImages/Phosphate.webp'
import phosphatepdf from '../SensorInfo/agriloSensorPDFS/PhosphateSensor.pdf'

import cadmiumimg from '../SensorInfo/agriloSensorImages/Cadmium.jpg'
import cadmiumpdf from '../SensorInfo/agriloSensorPDFS/CadmiumSensor.pdf'

import ionimg from '../SensorInfo/agriloSensorImages/ion.png'
import ionpdf from '../SensorInfo/agriloSensorPDFS/IonExchangeCapacitySensor.pdf'

import ironimg from '../SensorInfo/agriloSensorImages/Iron_lamp.jpg'
import ironpdf from '../SensorInfo/agriloSensorPDFS/IronSensor.pdf'

import manganeseimg from '../SensorInfo/agriloSensorImages/Manganese.jpg'
import manganesepdf from '../SensorInfo/agriloSensorPDFS/ManganeseSensor.pdf'

import nitrateimg from '../SensorInfo/agriloSensorImages/NO3.webp'
import nitratepdf from '../SensorInfo/agriloSensorPDFS/NitrateSensor.pdf'

import phimg from '../SensorInfo/agriloSensorImages/ph.webp'
import phpdf from '../SensorInfo/agriloSensorPDFS/pHSensor.pdf'

import antioxidantimg from '../SensorInfo/agriloSensorImages/Antioxidant.jpg'
import antioxidantpdf from '../SensorInfo/agriloSensorPDFS/AntioxidantCapacitySensor.pdf'

const sensorData = [
    {
      image: chromeimg, // Imported image file
      text: "Chromium",
      downloadLink: chromepdf, // Imported PDF file
    },
    {
        image: fluorineimg, // Imported image file
        text: "Fluoride",
        downloadLink: fluorinepdf, // Imported PDF file
    },
    {
        image: phosphateimg, // Imported image file
        text: "Phosphate",
        downloadLink: phosphatepdf, // Imported PDF file
    },
    {
        image: cadmiumimg, // Imported image file
        text: "Cadmium",
        downloadLink: cadmiumpdf, // Imported PDF file
    },
    {
        image: ionimg, // Imported image file
        text: "Ion Exchange Capacity",
        downloadLink: ionpdf, // Imported PDF file
    },
    {
        image: ironimg, // Imported image file
        text: "Iron",
        downloadLink: ironpdf, // Imported PDF file
    },
    {
        image: manganeseimg, // Imported image file
        text: "Manganese",
        downloadLink: manganesepdf, // Imported PDF file
    },
    {
        image: nitrateimg, // Imported image file
        text: "Nitrates",
        downloadLink: nitratepdf, // Imported PDF file
    },
    {
        image: phimg, // Imported image file
        text: "pH",
        downloadLink: phpdf, // Imported PDF file
    },
    {
        image: antioxidantimg, // Imported image file
        text: "Antioxidants",
        downloadLink: antioxidantpdf, // Imported PDF file
    },
];

const ProductsPage = () => {
    var subdomain = window.location.hostname.split('.');
    if (subdomain[0] === 'www') {
        subdomain = subdomain[1]
    } else {
        subdomain = subdomain[0]
    }

    const handleZenroClick = () => {
        window.location.href = 'https://zenro.pimasens.com'; 
    };

    const handleZenroPricingClick = () => {
        window.location.href = 'https://zenro.pimasens.com/pricing'
    }

    const handleAgriloClick = () => {
        window.location.href = 'https://agrilo.pimasens.com'; 
    };

    const handleAgriloPricingClick = () => {
        window.location.href = 'https://agrilo.pimasens.com/pricing'
    }

    return (
        <div className="Products">
            <Helmet>
                <meta name="description" content="Enhance your well-being with our Zenro app, and optimize your farming with our Agrilo app." />
                <meta name="keywords" content="Mental Health, Agriculture, Therapy, Performance, Mobile App" />
                <meta name="author" content="Pimasens" />
                <meta property="og:title" content="Products - Pimasens" />
                <meta property="og:description" content="Enhance your well-being with our Zenro app, and optimize your farming with our Agrilo app." />
                <meta property="og:url" content="https://www.pimasens.com/products" />
                <meta property="og:image" content="https://www.pimasens.com/BlackBackLogo.png" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <NavBar />
            {(subdomain === 'agrilo') && (
                <div className="sensor-guidelines-container">
                    {sensorData.map((sensor, index) => (
                        <div className="sensor-block" key={index}>
                            <img className="sensor-image" src={sensor.image} alt={`${sensor.text} button image`} />
                            <div className="sensor-overlay">
                                <span className="sensor-text">{sensor.text}</span>
                                <a className="sensor-button" href={sensor.downloadLink} target='_blank' rel="noopener noreferrer">
                                <FaDownload color="white" size={25} />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {(subdomain === 'zenro' || subdomain === 'pimasens') && (
                <div className="Products-container">
                    <div className="Products-box">
                        <h2>
                            <LinearGradient gradient={['to left', '#7EA6FF ,#F67F8D']} className="Products-header">
                                Zenro
                            </LinearGradient>
                        </h2>
                        <span className="Products-short-description">Enhance your well-being with comprehensive horomone tracking and mental health support</span>
                        <span className="Button-span">
                            <button className="Explore-Button" onClick={handleZenroClick}>EXPLORE</button>
                            <button className="Get-started-button" onClick={handleZenroPricingClick}>GET STARTED</button>
                        </span>
                        <img className="Zenro-promo-img" src={zenroPromoImage} alt="Zenro app displaying main splash" />
                    </div>

                    <div className="Products-box">
                        <h2>
                            <LinearGradient gradient={['to left', '#F6F64A ,#32E237']} className="Products-header">
                                Agrilo
                            </LinearGradient>
                        </h2>
                        <span className="Products-short-description">Optimize your farming efficiency with advanced soil, water, and crop analysis.</span>
                        <span className="Button-span">
                            <button className="Explore-Button" onClick={handleAgriloClick}>EXPLORE</button>
                            <button className="Get-started-button" onClick={handleAgriloPricingClick}>GET STARTED</button>
                        </span>
                        <img className="Zenro-promo-img" src={agriloPromoImage} alt="Zenro app displaying main splash" />
                    </div>
                </div>
            )}
            <BottomNav/>
        </div>
    );
};

export default ProductsPage;