import React, { useState, useEffect } from 'react';
import './AgriloHistoryPage.css';
import axios from 'axios';
import AgriloOverviewBox from '../components/AgriloOverviewBox';
import AgriloTestsBox from '../components/AgriloTestsBox';
import AgriloTestPreviewBox from '../components/AgriloTestPreviewBox';
import AgriloRecommendationsBox from '../components/AgriloRecommendationsBox';
import AgriloTestGroupBox from './components/AgriloTestGroupBox';

const AgriloHistoryPage = ({access, prefill}) => {
  // const { access } = location.state || {};
  const [tests, setTests] = useState([]);
  const [testGroup, setTestGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // const tests = [
  //   {
  //     TestID: 1,
  //     Name: "Soil Test 1",
  //     Date: "2024-08-08T12:00:00.000Z",
  //     Result: 7,
  //     Plant: "Barley",
  //     Chemical: "Nitrate",
  //     rating: 0,
  //   },
  //   {
  //     TestID: 2,
  //     Name: "Water Test 1",
  //     Date: "2024-08-05T10:30:00.000Z",
  //     Result: 32,
  //     Plant: "Barley",
  //     Chemical: "Nitrate",
  //     rating: 2,
  //   },
  //   {
  //     TestID: 3,
  //     Name: "Tissue Analysis",
  //     Date: "2024-08-02T15:45:00.000Z",
  //     Result: 24,
  //     Plant: "Barley",
  //     Chemical: "Nitrate",
  //     rating: 1,
  //   },
  //   {
  //     TestID: 4,
  //     Name: "Soil Test 2",
  //     Date: "2024-07-23T09:15:00.000Z",
  //     Result: 22,
  //     Plant: "Barley",
  //     Chemical: "Nitrate",
  //     rating: 1,
  //   },
  // ];

  useEffect(() => {
    axios.get('https://www.pimasens.com/api/agriloTest/getFilter/', {
      headers: {
        'Authorization': 'Bearer ' + access
      }
    })
      .then(response => {
        const data = response.data;

        if (data.error) {
          setError('An error occurred');
          setTests([]);
        } else {
          setTests(data);
        }
        setLoading(false);
      })
      .catch(error => {
        setError('An error occurred');
      });

      if (tests.length > 0) {
        setSelectedTest(tests[tests.length - 1]);
      }else{
        setTests([]);
      }
      axios.get('https://pimasens.com/api/AgriloTestGroup/', {
        headers: {
          'Authorization': 'Bearer ' + access
        }
      })
        .then(response => {
          const data = response.data;
          if (data.error) {
            setError('An error occurred');
          } else {
            setTestGroup(data);
          }
          setLoading(false); // Set loading to false after data is fetched
        })
        .catch(error => {
          setError('An error occurred');
          setLoading(false); // Set loading to false even if there's an error
        });
  }, []);

  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  return (
    <div className="Agrilo-HistoryPage">
      {(loading) ? (
        <div>Loading...</div> // Show a loading indicator while data is being fetched
      ) : (
        <>
          <h1 className='Agrilo-HistoryPage-h1'> History</h1>
          <div className='Agrilo-HistoryPage-line1' />
          <div className='Agrilo-HistoryPage-test-box'>
            <AgriloTestsBox testData={tests}
              selectedTest={selectedTest}
              access={access}
              onTestSelect={setSelectedTest}
              prefill={prefill} />
            <AgriloTestPreviewBox testData={selectedTest} prefill={prefill}/>
          </div>
          <div className='Agrilo-HistoryPage-test-group-box'>
            <AgriloTestGroupBox testData={testGroup}
              selectedGroup={selectedGroup}
              access={access}
              onGroupSelect={setSelectedGroup}
              prefill={prefill} />
              <AgriloTestPreviewBox testData={selectedGroup} prefill={prefill} type={"group"}/>
          </div>


          <AgriloOverviewBox access={access} prefill={prefill}/>
          {/* <AgriloRecommendationsBox/> */}
        </>
      )}
    </div>
  );
};

export default AgriloHistoryPage;