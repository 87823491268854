import React, { useState, useEffect, useRef } from 'react';
import './AgriloProfAssignedUsersBox.css';

const AgriloProfAssignedUsersBox = ({ users, selectedUsers, onUserSelect, selectOverview }) => {
    const [searchTerm, setSearchTerm] = useState(''); // For search input

    const handleItemClick = (users) => {
        onUserSelect(users);
        selectOverview(true)
    };

    const filteredUsers = users.filter((user) =>
        (user.user.FirstName + " " + user.user.LastName).toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="AgriloProfAssignedUsersBox">
            <h1 className='AgriloProfAssignedUsersBox-h1'>Overview</h1>
            <h1 className='AgriloProfAssignedUsersBox-userlist-text'>USER LIST</h1>
            <div className='AgriloProfAssignedUsersBox-filter-box'>
                <input type="text" className="AgriloProfAssignedUsersBox-search" placeholder="Search.." onChange={(event) => { setSearchTerm(event.target.value) }} />
            </div>
            {/* labels for patients */}
            <div className='AgriloProfAssignedUsersBox-labels'>
                <h2 className='AgriloProfAssignedUsersBox-h2 Name'>Name</h2>
                <h2 className='AgriloProfAssignedUsersBox-h2 Email'>Email</h2>
                <h2 className='AgriloProfAssignedUsersBox-h2 Phone'>Phone</h2>
            </div>
            {/* list of patients */}
            <div className="AgriloProfAssignedUsersBox-item-container">
                <nav className="AgriloProfAssignedUsersBox-items">
                    <ul className='AgriloProfAssignedUsersBox-ul'>
                        {filteredUsers.slice().reverse().map(users => (
                            <li
                                key={users.PatientID}
                                className={`users-item ${selectedUsers === users ? 'active' : ''}`}
                                onClick={() => handleItemClick(users)}
                            >
                                <div className='AgriloProfAssignedUsersBox-users-container'>
                                    <span className="AgriloProfAssignedUsersBox-users-name">{users.user.FirstName + " " + users.user.LastName}</span>
                                    <span className="AgriloProfAssignedUsersBox-users-email">{users.user.Email}</span>
                                    <span className="AgriloProfAssignedUsersBox-users-phone">{users.user.phone}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default AgriloProfAssignedUsersBox;