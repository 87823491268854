import React, { useState, useEffect } from 'react';
import './AgriloEditAccountPage.css';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const AgriloEditAccountPage = ({ access, onMenuItemSelect }) => {
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const handleFirstChange = (event) => {
        setUserInfo({ ...userInfo, FirstName: event.target.value });
    }
    const handleLastChange = (event) => {
        setUserInfo({ ...userInfo, LastName: event.target.value });
    }
    const handleEmailChange = (event) => {
        setUserInfo({ ...userInfo, Email: event.target.value });
    }
    const handlePhoneChange = (event) => {
        setUserInfo({ ...userInfo, phone: event.target.value });
    }
    const handlePostalChange = (event) => {
        setUserInfo({ ...userInfo, postal_code: event.target.value });
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getUser/', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error) {
                    setError('An error occurred');
                    navigate('/login');
                } else {
                    setUserInfo(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
                navigate('/login');
            });
    }, []);


    const onBackClick = () => {
        onMenuItemSelect('account');
    }
    const onSaveClick = () => {
        // check if any of the fields are empty
        // if any field is empty show an error message
        if (userInfo.FirstName === '' || userInfo.LastName === '' || userInfo.Email === '' || userInfo.phone === '') {
            setError('Please fill all fields');
            alert('Please fill all fields');
        }
        else if (password === '') {
            setError('Please input password');
            alert('Please input password');
        }
        else {
            axios.put('https://www.pimasens.com/api/updateUserLimited/', {
                FirstName: userInfo.FirstName,
                LastName: userInfo.LastName,
                Email: userInfo.Email,
                phone: userInfo.phone,
                postal_code: userInfo.postal_code,
                password: password
            }, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error == 'Incorrect password') {
                        setError('An error occurred');
                        alert('Incorrect password');
                    } else if (data.error) {
                        setError('An error occurred');
                        alert('An error occurred');
                    } else {
                        alert('Account updated successfully');
                    }
                })
                .catch(error => {
                    if (error.response.data.error === 'Incorrect password.') {
                        setError('An error occurred');
                        alert('Incorrect password');
                    } else {
                        setError('An error occurred');
                        alert('An error occurred');
                    }
                });
        }

    }

    return (
        <div className="Agrilo-EditAccountPage">
            <button className='Agrilo-EditAccountPage-back-btn' onClick={onBackClick}>
                <MdKeyboardArrowLeft className='Agrilo-EditAccountPage-back-icon' size={35} />
            </button>
            <div className='Agrilo-EditAccountPage-profile-box'>
                <h1 className='Agrilo-EditAccountPage-h1'>Edit Account</h1>
                <h2 className='Agrilo-EditAccountPage-h2'>Username</h2>
                <h3 className='Agrilo-EditAccountPage-h3'>{userInfo ? userInfo.Username : ''}</h3>
                <h2 className='Agrilo-EditAccountPage-h2'>First Name</h2>
                <input className='Agrilo-EditAccountPage-input' type='text' placeholder='First Name' value={userInfo ? userInfo.FirstName : ''} onChange={handleFirstChange} />
                <h2 className='Agrilo-EditAccountPage-h2'>Last Name</h2>
                <input className='Agrilo-EditAccountPage-input' type='text' placeholder='Last Name' value={userInfo ? userInfo.LastName : ''} onChange={handleLastChange} />
                <h2 className='Agrilo-EditAccountPage-h2'>Email</h2>
                <input className='Agrilo-EditAccountPage-input' type='text' placeholder='Email' value={userInfo ? userInfo.Email : ''} onChange={handleEmailChange} />
                <h2 className='Agrilo-EditAccountPage-h2'>Phone Number</h2>
                <input className='Agrilo-EditAccountPage-input' type='phone' placeholder='Phone Number' value={userInfo ? userInfo.phone : ''} onChange={handlePhoneChange} />
                <h2 className='Agrilo-EditAccountPage-h2'>postal code</h2>
                <input className='Agrilo-EditAccountPage-input' type='text' placeholder='postal code' value={userInfo ? userInfo.postal_code : ''} onChange={handlePostalChange} />
                <h2 className='Agrilo-EditAccountPage-h2'>Password</h2>
                <input className='Agrilo-EditAccountPage-input' type='password' placeholder='Password' value={password} onChange={handlePasswordChange} />
                <div className='Agrilo-EditAccountPage-button-box'>
                    <button className='Agrilo-EditAccountPage-cancel-button' onClick={onBackClick}>Cancel</button>
                    <button className='Agrilo-EditAccountPage-save-button' onClick={onSaveClick}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default AgriloEditAccountPage;