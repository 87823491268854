import React, { useState, useEffect } from 'react';
import './AgriloAccountPage.css';
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdEditSquare } from "react-icons/md";
import { MdLockPerson } from "react-icons/md";
import { CiCircleQuestion } from "react-icons/ci";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AgriloAccountPage = ({ access, onMenuItemSelect }) => {

    const navigate = useNavigate();
    const onSupportClick = () => {
        navigate('/contact-us');
    }
    const onEditAccountClick = () => {
        onMenuItemSelect('editAccount');
    }
    const onChangePasswordClick = () => {
        onMenuItemSelect('changePassword');
    }
    return (
        <div className="Agrilo-AccountPage">
            <h1 className='Agrilo-AccountPage-h1'> Account</h1>
            <div className='Agrilo-AccountPage-line1' />
            <div className='Agrilo-AccountPage-account-box'>
                <h2 className='Agrilo-AccountPage-h3'>Account</h2>
                <div className='Agrilo-AccountPage-edit-account-box' onClick={onEditAccountClick}>
                    <MdEditSquare className='Agrilo-AccountPage-edit-icon' size={30} />
                    <h2 className='Agrilo-AccountPage-h2'>Edit Account</h2>
                    <MdKeyboardArrowRight className='Agrilo-AccountPage-arrow-right' size={50} />
                </div>
                <div className='Agrilo-AccountPage-edit-password-box' onClick={onChangePasswordClick}>
                    <MdLockPerson className='Agrilo-AccountPage-edit-icon' size={30} />
                    <h2 className='Agrilo-AccountPage-h2-passowrd'>Change Password</h2>
                    <MdKeyboardArrowRight className='Agrilo-AccountPage-arrow-right' size={50} />
                </div>
            </div>
            <div className='Agrilo-AccountPage-help-box'>
                <h2 className='Agrilo-AccountPage-h3'>Help</h2>
                <div className='Agrilo-AccountPage-agrilo-help-box' onClick={onSupportClick}>
                    <CiCircleQuestion className='Agrilo-AccountPage-edit-icon' size={35} />
                    <h2 className='Agrilo-AccountPage-h2-agrilo-help'>Agrilo Support</h2>
                    <MdKeyboardArrowRight className='Agrilo-AccountPage-arrow-right' size={50} />
                </div>
            </div>
        </div>
    );
};

export default AgriloAccountPage;