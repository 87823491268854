import React, { useState, useEffect } from 'react';
import './AgriloProfUserTestsBox.css';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import AgriloRecentTestBox from '../../components/AgriloRecentTestBox';
import { IoFilterSharp } from "react-icons/io5";
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import classNames from "react-day-picker/style.module.css";
import axios from 'axios';
const AgriloProfUserTestsBox = ({ testData, selectedTest, onTestSelect, access, prefill, user }) => {
    const testColor = ['#CFBF05', '#4C6CFD', '#C83A31']
    const [filterBox, setFilterBox] = useState(false)
    const [givenTest, setTests] = useState(testData)
    const [inputFrom, setInputFrom] = useState('');
    const [inputTo, setInputTo] = useState('');
    const [error, setError] = useState('');
    const [cropType, setCropType] = useState('All');
    const [chemicalType, setChemicalType] = useState('All');
    const [specType, setSpecType] = useState('All');
    const [showFromPicker, setFromPicker] = useState(false);
    const [showToPicker, setToPicker] = useState(false);
    const [selectedDayTo, setSelectedDayTo] = useState('')
    const [selectedDayFrom, setSelectedDayFrom] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [inputValue, setInputValue] = useState('');
    const handleItemClick = (test) => {
        if (selectedTest !== test) { // Only update if a different test is clicked
            onTestSelect(test);
        }
    };

    const addDays = (dateString, days) => {
        /* Adds days to a date */
        var date = new Date(dateString);
        // add days
        date.setDate(date.getDate() + days);
        console.log(date)
        // convert to string
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(6, '0').slice(0, 6);

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

        return formattedDate;
    }

    const dateStringFormat = (dateString) => {
        /* converts date to format with time */
        const date = new Date(dateString); 
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(6, '0').slice(0, 6);

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

        return formattedDate;
    }

    const ratingAssign = (result) => {
        if (result == "high") {
            return 2
        } else if (result == "low") {
            return 0
        } else {
            return 1
        }
    }

    const handleFilterClick = () => {
        setFilterBox(!filterBox)
    }

    const handleCropTypeChange = (event) => {
        setCropType(event.target.value);
    }

    const handleChemicalTypeChange = (event) => {
        setChemicalType(event.target.value);
    }

    const handleSpecTypeChange = (event) => {
        setSpecType(event.target.value);
    }

    const onResetClick = () => {
        setInputFrom('');
        setInputTo('');
        setCropType('All');
        setChemicalType('All');
        setSpecType('All');
    }



    const handleToDayClick = (day) => {
        setSelectedDayTo(day);
        setInputTo(format(day, 'yyyy-MM-dd'));
    };


    const handleOKClickTo = () => {
        if (selectedDayTo != '') {
            setInputTo(format(selectedDayTo, 'yyyy-MM-dd'));
        }
        setToPicker(false);
    };

    const handleFromDayClick = (day) => {
        setSelectedDayFrom(day);
        setInputFrom(format(day, 'yyyy-MM-dd'));
    };


    const handleOKClickFrom = () => {
        if (selectedDayFrom != '') {
            setInputFrom(format(selectedDayFrom, 'yyyy-MM-dd'));
        }
        setFromPicker(false);
    };

    useEffect(() => {
        var fields = ''
        fields += `?AgronomistConnectionID=${user.AgronomistConnectionID}`;
        axios.get('https://www.pimasens.com/api/agriloProf/getUserFilter/' + fields, {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error) {
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests)
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }, [access]);

    const onFilterClicked = () => {
        var fields = ''
        fields += `?AgronomistConnectionID=${user.AgronomistConnectionID}`;
        if (inputFrom != '') {
            fields += `&StartDate=${dateStringFormat(inputFrom)}`;
        }
        if (inputTo != '') {
            fields += `&EndDate=${addDays(dateStringFormat(inputTo), 1)}`;
        }
        if (cropType != 'All') {
            fields += `&CropID=${cropType}`;
        }
        if (chemicalType != 'All') {
            fields += `&ChemicalID=${chemicalType}`;
        }

        axios.get('https://www.pimasens.com/api/agriloProf/getUserFilter/' + fields, {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error) {
                    setTests([]);
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests)
                    
                }
            })
            .catch(error => {
                setError('An error occurred');
            });

    }



    return (
        <div className="AgriloProfUserTestsBox">
            <div className='AgriloProfUserTestsBox-header'>
                <img src={TestsIcon} alt="shot1" className="AgriloProfUserTestsBox-icon" />
                <h1 className='AgriloProfUserTestsBox-h1'> Tests</h1>
            </div>
            <div className='AgriloProfUserTestsBox-line1' />
            <h2 className='AgriloProfUserTestsBox-h2 recent'>Recent</h2>
            <div className='AgriloProfUserTestsBox-recent-test'>
                {testData[0] && (
                    <AgriloRecentTestBox test={testData[0]} prefill={prefill} />
                )}
                {testData[1] && (
                    <AgriloRecentTestBox test={testData[1]} prefill={prefill} />
                )}
            </div>
            <div className='AgriloProfUserTestsBox-box-filter-button' >
                <h2 className='AgriloProfUserTestsBox-h2 all'>All</h2>
                <button className='AgriloProfUserTestsBox-filter-button' onClick={handleFilterClick}><IoFilterSharp />Filter</button>
            </div>
            <div className='AgriloProfUserTestsBox-line2' />
            {filterBox && (
                <div className='AgriloProfUserTestsBox-filter-box'>
                    <div className='AgriloProfTimeBox-date-container'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='AgriloProfUserTestsBox-filter-label'>From</label>
                            <input
                                className='AgriloProfUserTestsBox-filter-box-to'
                                type="text"
                                value={inputFrom}
                                readOnly
                                onClick={() => setFromPicker(true)}
                            />
                            {showFromPicker && (
                                <div className='AgriloProfUserTestsBox-filter-daypicker'>
                                    <DayPicker
                                        classNames={classNames}
                                        captionLayout="dropdown"
                                        selected={selectedDayFrom}
                                        onDayClick={handleFromDayClick}
                                        mode="single" // Changed to "single"
                                    />
                                    <button onClick={handleOKClickFrom}>OK</button>
                                </div>
                            )}
                            {showFromPicker && (
                                <div className='AgriloOverviewBox-overlay'></div>
                            )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='AgriloProfUserTestsBox-filter-label'>To</label>
                            <input
                                className='AgriloProfUserTestsBox-filter-box-to'
                                type="text"
                                value={inputTo}
                                readOnly
                                onClick={() => setToPicker(true)}
                            />
                            {showToPicker && (
                                <div className='AgriloProfUserTestsBox-filter-daypicker'>
                                    <DayPicker
                                        classNames={classNames}
                                        captionLayout="dropdown"
                                        selected={selectedDayTo}
                                        onDayClick={handleToDayClick} // Keep the same handler
                                        mode="single" // Changed to "single"
                                    />
                                    <button onClick={handleOKClickTo}>OK</button>
                                </div>
                            )}
                            {showToPicker && (
                                <div className='AgriloOverviewBox-overlay'></div>
                            )}
                        </div>
                    </div>
                    <div className='AgriloProfUserTestsBox-filter-box-select'>
                        <label className='AgriloProfUserTestsBox-filter-label'>Crop Type:</label>
                        <select value={cropType} className='AgriloProfUserTestsBox-filter-select' onChange={handleCropTypeChange}>
                            <option value="All">All</option>
                            {prefill["crop_types"].map(crop => (
                                <option value={crop.CropTypeID}>{crop.CropName}</option>
                            ))}
                        </select>
                    </div>
                    <div className='AgriloProfUserTestsBox-filter-box-select'>
                        <label className='AgriloProfUserTestsBox-filter-label'>Chemical Type:</label>
                        <select value={chemicalType} className='AgriloProfUserTestsBox-filter-select' onChange={handleChemicalTypeChange}>
                            <option value="All">All</option>
                            {prefill["variants"].map(chemical => (
                                <option value={chemical.VariantID}>{chemical.VariantName}</option>
                            ))}
                        </select>
                    </div>
                    {/* <div className='AgriloProfUserTestsBox-filter-box-select'>
                        <label className='AgriloProfUserTestsBox-filter-label'>Spec Type</label>
                        <select value={specType} className='AgriloProfUserTestsBox-filter-select' onChange={handleSpecTypeChange}>
                            <option value="All">All</option>
                            {prefill["specs"].map(spec => (
                                <option value={spec.SpecName}>{spec.SpecName}</option>
                            ))}
                        </select>
                    </div> */}
                    {/* <div className='AgriloProfUserTestsBox-filter-box-select'>
                        <label className='AgriloProfUserTestsBox-filter-label'>Rating</label>
                        <select className='AgriloProfUserTestsBox-filter-select'>
                            <option value="All">All</option>
                            <option value="Good">Good</option>
                            <option value="Average">Average</option>
                            <option value="Bad">Bad</option>
                        </select>
                    </div> */}
                    <div className='AgriloProfUserTestsBox-filter-box-buttons'>
                        <button className='AgriloProfUserTestsBox-filter-box-reset' onClick={onResetClick}>Reset</button>
                        <button className='AgriloProfUserTestsBox-filter-box-filter' onClick={onFilterClicked}>Filter</button>
                    </div>
                </div>
            )}
            {/* list of test container */}
            <div className="AgriloProfUserTestsBox-item-container">
                <nav className="AgriloProfUserTestsBox-items">

                    <ul className='AgriloProfUserTestsBox-ul'>
                        {givenTest.slice().map(test => (
                            <li
                                key={test.TestID}
                                className={`AgriloProfUserTestsBox-test-item ${selectedTest === test ? 'active' : ''}`}
                                onClick={() => handleItemClick(test)}
                            >
                                <span className="AgriloProfUserTestsBox-item-date">{new Date(test.TimeCreated).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>
                                <span className="AgriloProfUserTestsBox-plant-type">
                                    {prefill["crop_types"][test.CropTypeID - 1]["CropName"]}
                                </span>
                                <span className="AgriloProfUserTestsBox-chemical-type">
                                    {prefill["variants"][test.VariantID - 1]["VariantName"]}
                                </span>
                                <div className='AgriloProfUserTestsBox-unit-amount-box'>
                                    <span className="AgriloProfUserTestsBox-unit-amount" style={{ color: testColor[ratingAssign(test.TestRangeResult)] }} >{test.PpmRecorded.toFixed(3)}</span>
                                    <span className="AgriloProfUserTestsBox-units">PPM</span>
                                </div>


                                {/* <span className="AgriloProfUserTestsBox-item-name">{test.name}</span> */}

                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

        </div>
    );
};

export default AgriloProfUserTestsBox;

