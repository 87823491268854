import React, { useState, useEffect } from 'react';
import './AgriloTestPreviewBox.css';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import placeholder from '../assets/agriloIcons/AgriloPlaceholder2.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { el, tr } from 'react-day-picker/locale';

const AgriloTestPreviewBox = ({ testData, prefill, type }) => {
  const color = ['#CFBF05', '#4C6CFD', '#C83A31']
  const recommendation = ['Low', 'Optimal', 'High']
  const [rating, setRating] = useState(1);
  const [max, setMax] = useState(1);
  const [min, setMin] = useState(0);
  const [explanation, setExplanation] = useState('');
  const [formattedText, setFormattedText] = useState('');
  const [value, setValue] = useState(0);
  const [recommendationText, setRecommendationText] = useState('');
  useEffect(() => {
    if (testData != null) {
      if (testData.TestRangeResult == "high") {
        setRating(2)
      } else if (testData.TestRangeResult == "low") {
        setRating(0)
      } else {
        setRating(1)
      }

    }

    if (type == "group") {
      setValue(testData != null ? testData.Average : 0)
      setRecommendationText(testData != null ? testData.Recommendations : "")
    }else{
      setValue(testData != null ? testData.PpmRecorded : 0)
      setRecommendationText(testData != null ? testData.Desc : "")
    }

    if (testData != null && prefill != null) {
      for (var i = 0; i < prefill["ranges"].length; i++) {
        if (prefill["ranges"][i]["VariantID"] == testData.VariantID && prefill["ranges"][i]["CropTypeID"] == testData.CropTypeID) {
          try {
            setMax(prefill["ranges"][i]["MaxPpm"])
            setMin(prefill["ranges"][i]["MinPpm"])
          } catch {
            setMax(1)
            setMin(0)
          }
        }
      }
    }

    try {
      const exp = testData != undefined ? recommendationText.split("\n\n")[0] : "no test"

      const text = testData != undefined ? recommendationText.split('\n').slice(1).map((line, index) => {
        const boldStart = line.indexOf('**');
        const boldEnd = line.indexOf('**', boldStart + 2);
        if (boldStart !== -1 && boldEnd !== -1) {
          const boldText = line.substring(boldStart + 2, boldEnd);
          const normalText = line.substring(boldEnd + 2);
          return (
            <li key={index} style={{ marginBottom: '1em' }}>
              <strong>{boldText}</strong>{normalText}
            </li>
          );
        } else {
          return <p key={index} style={{ marginBottom: '1em' }}>{line}</p>;
        }
      }) : "no test";

      setExplanation(exp)
      setFormattedText(text)
    } catch {
      setExplanation("Error")
      setFormattedText("")
    }

  }, [testData]);
  return (
    <div className="AgriloTestPreviewBox">
      <img src={TestsIcon} alt="shot1" className="AgriloTestPreviewBox-icon" />
      <h1 className='AgriloTestPreviewBox-h1'>Test Preview</h1>
      <div className='AgriloTestPreviewBox-line1' />
      <h2 className='AgriloTestPreviewBox-h2'>{testData != null ? testData.Name : "null test"} </h2>
      <h3 className='AgriloTestPreviewBox-h3'>
        {testData != null ?
          new Date(testData.TimeCreated).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }) : "null test"} </h3>
      <div className='AgriloTestPreviewBox-placeholder' >
        <div style={{ width: '15rem', height: '15rem', position: 'relative' }}>
          <CircularProgressbar strokeWidth={5} value={testData != null ? value : "0"} circleRatio={0.75} maxValue={max} minValue={min} text={testData != null ? value.toFixed(2) : "0"}
            styles={buildStyles({
              pathColor: color[rating],
              fontWeight: '500',
              fontFamily: 'Poppins',
              textSize: '1rem',
              rotation: 1 / 2 + 1 / 8,
              trailColor: "#eee",
              classes: "AgriloTestPreviewBox-value-text",
              textColor: color[rating],
            })} />
        </div>
        <h1 className='AgriloTestPreviewBox-chemical'>{testData != null ? prefill["variants"][testData.VariantID - 1]["VariantName"] : "0"}</h1>
        <h1 className='AgriloTestPreviewBox-metric'>ppm</h1>
        <h1 className='AgriloTestPreviewBox-range-low'>{min}</h1>
        <h1 className='AgriloTestPreviewBox-range-high'>{max}</h1>
        <div className='AgriloTestPreviewBox-rating' style={{ backgroundColor: color[rating] }}>
          <h1 className='AgriloTestPreviewBox-rating-text'>{recommendation[rating]}</h1>
        </div>
        <h1 className='AgriloTestPreviewBox-range'>{min}-{max}ppm</h1>
      </div>
      <h4 className='AgriloTestPreviewBox-h4'>Recommendation</h4>
      <h5 className='AgriloTestPreviewBox-h5'>{testData != null ? explanation : "no test"}</h5>
      <h5 className='AgriloTestPreviewBox-h5'>{testData != null ? formattedText : ""}</h5>
    </div>
  );
};

export default AgriloTestPreviewBox;
