import React, { useState } from 'react';
import './AgriloProfNavagatorBar.css';
import CalendarIcon from '../assets/agriloIcons/CalendarIcon.png';
import TasksIcon from '../assets/agriloIcons/TasksIcon.png';
import PinnedIcon from '../assets/agriloIcons/PinnedIcon.png';
import OverviewIcon from '../assets/agriloIcons/OverviewIcon.png';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import RecommendationsIcon from '../assets/agriloIcons/RecommendationsIcon.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdAccountCircle } from "react-icons/md";
const AgriloProfNavagatorBar = ({ onMenuItemSelect, userInfo }) => {

    const navigate = useNavigate();



    const handleMenuItemClick = (menuItem) => {
        onMenuItemSelect(menuItem);
    };

    const handleSignOutClick = () => {
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/');
    }
    return (
        <div className="AgriloProfNavagatorBar">
            <button className='AgriloProfNavagatorBar-summary-btn' onClick={() => handleMenuItemClick('event')}>Event
                <i className="fa fa-caret-down"></i>
            </button>
            {/* <button className='AgriloProfNavagatorBar-summary-btn' onClick={() => toggleMenu('summary')}>Summary
                <i className="fa fa-caret-down"></i>
            </button> */}

            <ul className="AgriloProfNavagatorBar-summary-menu">
                {/* Your menu items go here */}
                <li className='AgriloProfNavagatorBar-li' onClick={() => handleMenuItemClick('event')}>
                    <img src={CalendarIcon} alt="shot1" className="CalendarIcon" />
                    Event
                </li>
                <li className='AgriloProfNavagatorBar-li' onClick={() => handleMenuItemClick('event')}>
                    <img src={TasksIcon} alt="shot1" className="TasksIcon" />
                    Time
                </li>
                <li className='AgriloProfNavagatorBar-li' onClick={() => handleMenuItemClick('event')}>
                    <img src={PinnedIcon} alt="shot1" className="PinnedIcon" />
                    Users
                </li>
            </ul>

            <button className='AgriloProfNavagatorBar-history-btn' onClick={() => handleMenuItemClick('user')}>Users
                <i className="fa fa-caret-down"></i>
            </button>
            {/* <button className='AgriloProfNavagatorBar-history-btn' onClick={() => toggleMenu('user')}>Users
                <i className="fa fa-caret-down"></i>
            </button> */}

            <ul className="AgriloProfNavagatorBar-history-menu">
                {/* Your menu items go here */}
                <li className='AgriloProfNavagatorBar-li'>
                    <img src={OverviewIcon} alt="shot1" className="AgriloProfNavagatorBar-OverviewIcon" />
                    overview
                </li>
                <li className='AgriloProfNavagatorBar-li' onClick={() => handleMenuItemClick('user')}>
                    <img src={TestsIcon} alt="shot1" className="AgriloProfNavagatorBar-TestsIcon" />
                    Tests
                </li>
                <li className='AgriloProfNavagatorBar-li' onClick={() => handleMenuItemClick('user')}>
                    <img src={RecommendationsIcon} alt="shot1" className="AgriloProfNavagatorBar-RecommendationsIcon" />
                    Recommendations
                </li>
            </ul>

            <button className='AgriloProfNavagatorBar-account-btn' onClick={() => handleMenuItemClick('account')}>
                <MdAccountCircle className='AgriloNavagatorBar-icon' />
                Account
            </button>

            <h1 className='AgriloProfNavagatorBar-h1' onClick={() => handleSignOutClick()}>SIGN OUT</h1>
        </div >
    );
};

export default AgriloProfNavagatorBar;
