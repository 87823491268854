import React, { useState, useEffect } from 'react';
import './AgriloTestGroupBox.css';
import TestsIcon from '../../assets/agriloIcons/TestsIcon.png';
import { IoFilterSharp } from "react-icons/io5";
import { DayPicker } from 'react-day-picker';
import { add, format, set } from 'date-fns';
import classNames from "react-day-picker/style.module.css";
import { AiOutlineClose } from "react-icons/ai";
import axios from 'axios';
import { TiPlus } from "react-icons/ti";
const AgriloTestGroupBox = ({ testData, selectedGroup, onGroupSelect, access, prefill }) => {
    const testColor = ['#CFBF05', '#4C6CFD', '#C83A31']
    const [filterBox, setFilterBox] = useState(false)
    const [givenTest, setTests] = useState([])
    const [inputFrom, setInputFrom] = useState('');
    const [inputTo, setInputTo] = useState('');
    const [error, setError] = useState('');
    const [cropType, setCropType] = useState(prefill["crop_types"][0].CropTypeID);
    const [chemicalType, setChemicalType] = useState(prefill["variants"][0].VariantID);
    const [specType, setSpecType] = useState('All');
    const [showFromPicker, setFromPicker] = useState(false);
    const [showToPicker, setToPicker] = useState(false);
    const [selectedDayTo, setSelectedDayTo] = useState('')
    const [selectedDayFrom, setSelectedDayFrom] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [inputValue, setInputValue] = useState('');
    const [addMenu, setAddMenu] = useState(false);

    const handleAddButtonClick = () => {
        setAddMenu(!addMenu)
    }

    const addDays = (dateString, days) => {
        /* Adds days to a date */
        var date = new Date(dateString);
        // add days
        date.setDate(date.getDate() + days);
        console.log(date)
        // convert to string
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(6, '0').slice(0, 6);

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

        return formattedDate;
    }

    const dateStringFormat = (dateString) => {
        /* converts date to format with time */
        const date = new Date(dateString); 
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(6, '0').slice(0, 6);

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

        return formattedDate;
    }

    const handleAddClicked = () => {
        if (inputFrom == '') {
            alert('Please enter a start date');
        } else if (inputTo == '') {
            alert('Please enter an end date');
        } else {
            //convert date to UTC
            var To = addDays(inputTo, 1);
            var From = dateStringFormat(inputFrom);
            var data = {
                "From": From,
                "To": To,
                "CropTypeID": cropType,
                "VariantID": chemicalType
            }

            axios.post('https://www.pimasens.com/api/AgriloTestGroup/', data, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error == "no tests found with assoicated grouping") {
                        alert('no tests found with assoicated grouping');
                        setError('An error occurred');
                    } else {
                        alert('Group added');
                        updateTests()
                        setAddMenu(false)
                    }
                }
                )
                .catch(error => {
                    alert('no tests found with assoicated grouping');
                    setError('An error occurred');
                }
                );
        }
    }

    const updateTests = () => {
        axios.get('https://www.pimasens.com/api/AgriloTestGroup/', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error) {
                    setError('An error occurred');
                } else {
                    setTests(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }

    const handleItemClick = (test) => {
        if (selectedGroup !== test) { // Only update if a different test is clicked
            onGroupSelect(test);
        }
    };

    const ratingAssign = (result) => {
        if (result == "high") {
            return 2
        } else if (result == "low") {
            return 0
        } else {
            return 1
        }
    }

    const handleFilterClick = () => {
        setFilterBox(!filterBox)
    }

    const handleCropTypeChange = (event) => {
        setCropType(event.target.value);
    }

    const handleChemicalTypeChange = (event) => {
        setChemicalType(event.target.value);
    }

    const handleSpecTypeChange = (event) => {
        setSpecType(event.target.value);
    }

    const onResetClick = () => {
        setInputFrom('');
        setInputTo('');
        setCropType('All');
        setChemicalType('All');
        setSpecType('All');
    }



    const handleToDayClick = (day) => {
        setSelectedDayTo(day);
        setInputTo(format(day, 'yyyy-MM-dd'));
    };


    const handleOKClickTo = () => {
        if (selectedDayTo != '') {
            setInputTo(format(selectedDayTo, 'yyyy-MM-dd'));
        }
        setToPicker(false);
    };

    const handleFromDayClick = (day) => {
        setSelectedDayFrom(day);
        setInputFrom(format(day, 'yyyy-MM-dd'));
    };


    const handleOKClickFrom = () => {
        if (selectedDayFrom != '') {
            setInputFrom(format(selectedDayFrom, 'yyyy-MM-dd'));
        }
        setFromPicker(false);
    };

    const onFilterClicked = () => {
        var hasFields = false
        var fields = ''
        if (inputFrom != '') {
            fields += hasFields ? `&StartDate=${inputFrom}` : `?StartDate=${inputFrom}`;
            hasFields = true
        }
        if (inputTo != '') {
            fields += hasFields ? `&EndDate=${inputTo}` : `?EndDate=${inputTo}`;
            hasFields = true
        }
        if (cropType != 'All') {
            fields += hasFields ? `&CropID=${cropType}` : `?CropID=${cropType}`;
            hasFields = true
        }
        if (chemicalType != 'All') {
            fields += hasFields ? `&VariantID=${chemicalType}` : `?VariantID=${chemicalType}`;
            hasFields = true
        }

        axios.get('https://www.pimasens.com/api/agriloTest/getFilter/' + fields, {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error) {
                    setTests([]);
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests)
                }
            })
            .catch(error => {
                setError('An error occurred');
            });

    }

    useEffect(() => {
        if (testData.length > 0) {
            setTests(testData)
        }
    }, [testData])


    return (
        <div className="AgriloTestGroupBox">
            <div className='AgriloTestBox-header'>
                <img src={TestsIcon} alt="shot1" className="AgriloTestGroupBox-icon" />
                <h1 className='AgriloTestGroupBox-h1'> Test Groups</h1>
            </div>
            <button className='AgriloTestGroupBox-add-button' onClick={handleAddButtonClick}><TiPlus size={20} /></button>
            <div className='AgriloTestGroupBox-line1' />
            {addMenu && (
                <div className='AgriloOverviewBox-popup-menu'>
                    <AiOutlineClose size={20} className='AgriloOverviewBox-close-icon' onClick={handleAddButtonClick} />
                    <div className='AgriloOverviewBox-popup-menu-box'>

                        <div className='AgriloProfTimeBox-date-container'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label className='AgriloTestsBox-filter-label'>From</label>
                                <input
                                    className='AgriloTestsBox-filter-box-to'
                                    type="text"
                                    value={inputFrom}
                                    readOnly
                                    onClick={() => setFromPicker(true)}
                                />
                                {showFromPicker && (
                                    <div className='AgriloTestsBox-filter-daypicker'>
                                        <DayPicker
                                            classNames={classNames}
                                            captionLayout="dropdown"
                                            selected={selectedDayFrom}
                                            onDayClick={handleFromDayClick}
                                            mode="single" // Changed to "single"
                                        />
                                        <button onClick={handleOKClickFrom}>OK</button>
                                    </div>
                                )}
                                {showFromPicker && (
                                    <div className='AgriloOverviewBox-overlay'></div>
                                )}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label className='AgriloTestsBox-filter-label'>To</label>
                                <input
                                    className='AgriloTestsBox-filter-box-to'
                                    type="text"
                                    value={inputTo}
                                    readOnly
                                    onClick={() => setToPicker(true)}
                                />
                                {showToPicker && (
                                    <div className='AgriloTestsBox-filter-daypicker'>
                                        <DayPicker
                                            classNames={classNames}
                                            captionLayout="dropdown"
                                            selected={selectedDayTo}
                                            onDayClick={handleToDayClick} // Keep the same handler
                                            mode="single" // Changed to "single"
                                        />
                                        <button onClick={handleOKClickTo}>OK</button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='AgriloTestsBox-filter-box-select'>
                            <label className='AgriloTestsBox-filter-label'>Crop Type:</label>
                            <select value={cropType} className='AgriloTestsBox-filter-select' onChange={handleCropTypeChange}>
                                {prefill["crop_types"].map(crop => (
                                    <option value={crop.CropTypeID}>{crop.CropName}</option>
                                ))}
                            </select>
                        </div>
                        <div className='AgriloTestsBox-filter-box-select'>
                            <label className='AgriloTestsBox-filter-label'>Chemical Type:</label>
                            <select value={chemicalType} className='AgriloTestsBox-filter-select' onChange={handleChemicalTypeChange}>
                                {prefill["variants"].map(chemical => (
                                    <option value={chemical.VariantID}>{chemical.VariantName}</option>
                                ))}
                            </select>
                        </div>
                        <button className='AgriloOverviewBox-save-button' onClick={handleAddClicked}>Add Group</button>
                    </div>
                </div>
            )}
            {/*<div className='AgriloTestGroupBox-box-filter-button' >
                <h2 className='AgriloTestGroupBox-h2 all'>All</h2>
                <button className='AgriloTestGroupBox-filter-button' onClick={handleFilterClick}><IoFilterSharp />Filter</button>
            </div>
            <div className='AgriloTestGroupBox-line2' />
            {filterBox && (
                <div className='AgriloTestGroupBox-filter-box'>
                    <div className='AgriloProfTimeBox-date-container'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='AgriloTestGroupBox-filter-label'>From</label>
                            <input
                                className='AgriloTestGroupBox-filter-box-to'
                                type="text"
                                value={inputFrom}
                                readOnly
                                onClick={() => setFromPicker(true)}
                            />
                            {showFromPicker && (
                                <div className='AgriloTestGroupBox-filter-daypicker'>
                                    <DayPicker
                                        classNames={classNames}
                                        captionLayout="dropdown"
                                        selected={selectedDayFrom}
                                        onDayClick={handleFromDayClick}
                                        mode="single" // Changed to "single"
                                    />
                                    <button onClick={handleOKClickFrom}>OK</button>
                                </div>
                            )}
                            {showFromPicker && (
                                <div className='AgriloOverviewBox-overlay'></div>
                            )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='AgriloTestGroupBox-filter-label'>To</label>
                            <input
                                className='AgriloTestGroupBox-filter-box-to'
                                type="text"
                                value={inputTo}
                                readOnly
                                onClick={() => setToPicker(true)}
                            />
                            {showToPicker && (
                                <div className='AgriloTestGroupBox-filter-daypicker'>
                                    <DayPicker
                                        classNames={classNames}
                                        captionLayout="dropdown"
                                        selected={selectedDayTo}
                                        onDayClick={handleToDayClick} // Keep the same handler
                                        mode="single" // Changed to "single"
                                    />
                                    <button onClick={handleOKClickTo}>OK</button>
                                </div>
                            )}
                            {showToPicker && (
                                <div className='AgriloOverviewBox-overlay'></div>
                            )}
                        </div>
                    </div>
                    <div className='AgriloTestGroupBox-filter-box-select'>
                        <label className='AgriloTestGroupBox-filter-label'>Crop Type:</label>
                        <select value={cropType} className='AgriloTestGroupBox-filter-select' onChange={handleCropTypeChange}>
                            <option value="All">All</option>
                            {prefill["crop_types"].map(crop => (
                                <option value={crop.CropTypeID}>{crop.CropName}</option>
                            ))}
                        </select>
                    </div>
                    <div className='AgriloTestGroupBox-filter-box-select'>
                        <label className='AgriloTestGroupBox-filter-label'>Chemical Type:</label>
                        <select value={chemicalType} className='AgriloTestGroupBox-filter-select' onChange={handleChemicalTypeChange}>
                            <option value="All">All</option>
                            {prefill["variants"].map(chemical => (
                                <option value={chemical.VariantID}>{chemical.VariantName}</option>
                            ))}
                        </select>
                    </div> */}
            {/* <div className='AgriloTestGroupBox-filter-box-select'>
                        <label className='AgriloTestGroupBox-filter-label'>Spec Type</label>
                        <select value={specType} className='AgriloTestGroupBox-filter-select' onChange={handleSpecTypeChange}>
                            <option value="All">All</option>
                            {prefill["specs"].map(spec => (
                                <option value={spec.SpecName}>{spec.SpecName}</option>
                            ))}
                        </select>
                    </div> */}
            {/* <div className='AgriloTestGroupBox-filter-box-select'>
                        <label className='AgriloTestGroupBox-filter-label'>Rating</label>
                        <select className='AgriloTestGroupBox-filter-select'>
                            <option value="All">All</option>
                            <option value="Good">Good</option>
                            <option value="Average">Average</option>
                            <option value="Bad">Bad</option>
                        </select>
                    </div> */}
            {/* <div className='AgriloTestGroupBox-filter-box-buttons'>
                        <button className='AgriloTestGroupBox-filter-box-reset' onClick={onResetClick}>Reset</button>
                        <button className='AgriloTestGroupBox-filter-box-filter' onClick={onFilterClicked}>Filter</button>
                    </div>
                </div>
            )} */}
            {/* list of test container */}
            <div className="AgriloTestGroupBox-item-container">
                <nav className="AgriloTestGroupBox-items">

                    <ul className='AgriloTestGroupBox-ul'>
                        {givenTest.slice().map(test => (
                            <li
                                key={test.TestID}
                                className={`AgriloTestGroupBox-test-item ${selectedGroup === test ? 'active' : ''}`}
                                onClick={() => handleItemClick(test)}
                            >
                                {/* <span className="AgriloTestGroupBox-item-name">{prefill["specs"]["0"]["SpecName"]}</span> */}
                                <span className="AgriloTestGroupBox-item-date">{new Date(test.TimeCreated).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>
                                <span className="AgriloTestGroupBox-plant-type">
                                    {prefill["crop_types"][test.CropTypeID - 1]["CropName"]}
                                </span>
                                <span className="AgriloTestGroupBox-chemical-type">
                                    {prefill["variants"][test.VariantID - 1]["VariantName"]}
                                </span>
                                <div className='AgriloTestGroupBox-unit-amount-box'>
                                    <span className="AgriloTestGroupBox-unit-amount" style={{ color: testColor[ratingAssign(test.TestRangeResult)] }} >{test.Average.toFixed(3)}</span>
                                    <span className="AgriloTestGroupBox-units">PPM</span>
                                </div>


                                {/* <span className="AgriloTestGroupBox-item-name">{test.name}</span> */}

                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            {addMenu && (
                <div className='AgriloOverviewBox-overlay'></div>
            )}
        </div>
    );
};

export default AgriloTestGroupBox;

