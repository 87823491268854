import React from 'react';
import './ZenroProductsPage.css';
import { LinearGradient } from 'react-text-gradients';
import { useRef, useState } from "react";

import microscope from '../assets/agriloIcons/microscope.png';
import AI from '../assets/agriloIcons/AI.png';
import leaf from '../assets/agriloIcons/leaf.png';
import agriloProdVideo from '../assets/AgriloVideos/FinalEdit_1.mp4';
import agriloPhones from '../assets/pimaspecScreenshots/agriloPhones.png'

import CustomVideoPlayer from '../agrilo_prof_pages/components/CustomVIdeoPlayer';
import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';



const AgriloProductPage = () => {
    return (
        <div className="ZenroProductPage">
            <NavBar />
            <main className='ZenroProductPage-Container'>
                <div className='ZenroPP-titleDiv'>
                    <div style={{paddingBottom:"5rem"}}>
                    <h1 className='ZenroPP-Title'>
                        <LinearGradient gradient={['to left', 'rgb(246, 246, 74), rgb(50, 226, 55)']} className="Products-header">
                            Agrilo
                        </LinearGradient>
                    </h1>
                    <p className='ZenroPP-TitleText'>Optimize your soil with our suite of testing features</p>
                    </div>
                </div>
                <div className="ZenroPP-Main-Content" style={{marginBottom: '5rem'}}>
                    <div className="ZenroPP-InfoBox">
                        <img src={microscope} alt='Microscope image' />
                        <h3>Fast & Simple Soil Testing</h3>
                        <p>Instantly analyze soil, water, and food using a smartphone and colorimetric strips.</p>
                    </div>
                    <div className="ZenroPP-InfoBox">
                        <img src={AI} alt='robot brain image' />
                        <h3>AI-Driven Insights</h3>
                        <p>Get instant, tailored recommendations backed by agricultural research.</p>
                    </div>
                    <div className="ZenroPP-InfoBox">
                    <img src={leaf} alt='Microscope image' />
                        <h3>Smart Farming Tools</h3>
                        <p>Track test history, visualize trends, and set task reminders for better crop management.</p>
                        <p>Access a web portal to consult with specialists for deeper insights.</p>
                    </div>
                </div>
                <div className='ZenroPP-Video-Content'>
                    <CustomVideoPlayer videoSrc={agriloProdVideo}/>
                    <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">Explore Agrilo</h2>
                            <p className='ZenroPP-TitleText'>The agrilo app is your one-stop shop for soil analytics. From farmland to personal gardens, we have the tools to help you succeed.</p>
                    </div>
                </div>
                <div className='ZenroPP-Main-Content' style={{background: 'transparent', alignItems: 'center'}}>
                    <div className="ZenroPP-TextBlock">
                        <h2 className="ZenroPP-TextBlock-Title">Download</h2>
                        <p className='ZenroPP-TitleText'>The agrilo app is your one-stop shop for soil analytics. From farmland to personal gardens, we have the tools to help you succeed.</p>
                        <div className='ZenroPP-Download-Container'>
                            <button className='ZenroPP-Download-Button-Style'>iOS</button>
                            <button className='ZenroPP-Download-Button-Style'>Android</button>
                        </div>
                    </div>
                    <img src={agriloPhones} alt='image of app on two phones' style={{width: "30vw"}}/>
                </div>
            </main>
            <BottomNav/>
        </div>
    );
};

export default AgriloProductPage;